@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
:root {
  --font-family: "Open Sans", sans-serif;
  --font-stack: var(--font-family);
  --primary: #0066cc;
  --primary-dark: #0257ac;
  --border-radius-tiny: 2px;
  --border-radius-sm: 4px;
  --border-radius: 4px;
  --border-radius-md: 5px;
  --border-radius-lg: 6px;
}

* {
  letter-spacing: normal !important;
}

.dropdown-help,
.form-reviews,
.footer-powered {
  display: none !important;
}

.btn,
.document-link-badge,
.nav-link,
.dropdown-item,
.dropdown-menu a,
.list-row {
  transition: all 0.2s ease;
}

.help-box.small {
  font-size: var(--text-2xs) !important;
}

body,
.body,
.recent-item,
.recent-item.notification-item b,
.notifications-list .notification-item-tabs .notifications-category,
.form-sidebar-items,
.attachments-actions a {
  color: var(--text-color);
}

.show-all-activity {
  font-size: var(--text-2xs);
}

.list-row,
.list-row-head {
  padding: 20px 20px 20px 0px;
  border-radius: var(--border-radius);
}

.grid-heading-row {
  background-color: var(--navbar-bg);
}

.list-row-head {
  background-color: var(--subtle-fg);
  border: 1px solid var(--border-color);
}

.list-row .list-row-activity .frappe-timestamp,
.list-row-head .list-row-activity .frappe-timestamp {
  font-size: var(--text-2xs);
}

.form-links .form-link-title {
  color: var(--primary);
  font-size: var(--text-xs);
}

.form-links .document-link .open-notification {
  color: var(--danger);
  background-color: var(--subtle-fg);
  font-weight: var(--weight-semibold);
  font-size: var(--text-xs);
}

.form-links,
.document-link,
.document-link-badge {
  background-color: var(--subtle-fg) !important;
  margin-bottom: var(--padding-2xs) !important;
}

.document-link {
  margin-bottom: var(--padding-2xs) !important;
}

.document-link-badge {
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: flex-end !important;
  align-items: center !important;
  gap: 4px !important;
}

.count {
  background-color: var(--subtle-fg) !important;
}

.document-link-badge .badge-link {
  margin-right: 2px;
}

.document-link .open-notification {
  margin-right: 0px !important;
  margin-left: -10px !important;
}

.document-link .btn-new.btn-secondary.btn-xs.icon-btn {
  padding: 0px !important;
  font-size: var(--text-2xs) !important;
  background-color: var(--subtle-fg);
}
.document-link .btn-new.btn-secondary.btn-xs.icon-btn .icon {
  width: 12px !important;
  height: 12px !important;
}

.btn.primary-action {
  font-weight: var(--weight-semibold);
}

.form-control {
  border: 1px solid var(--border-color);
}

.control-label {
  color: var(--gray-600);
  font-weight: 500;
}

.like-disabled-input {
  font-weight: 650;
  background-color: var(--navbar-bg);
}

.ql-toolbar.ql-snow {
  background-color: var(--navbar-bg) !important;
}

.frappe-control .ql-editor:not(.read-mode) {
  background-color: var(--control-bg);
}
.frappe-control .address-box {
  background-color: var(--subtle-fg);
  padding: var(--padding-md);
}

.print-preview-wrapper {
  border: 1px solid var(--border-color);
  background-color: var(--navbar-bg);
}

.print-preview {
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.3);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}

.print-format-container {
  border-radius: var(--border-radius);
}

.btn-group button {
  border: 1px solid var(--border-color);
}

.btn-group {
  border: none !important;
}

.page-actions.btn-primary {
  background-color: var(--primary) !important;
}

.btn-default:not(.btn-primary) {
  background-color: var(--navbar-bg);
}
.btn-default:not(.btn-primary):active, .btn-default:not(.btn-primary):hover {
  background-color: var(--gray-300);
}

.btn-xs {
  font-size: var(--text-xs) !important;
}

.list-paging-area .btn-group .btn-paging.btn-info,
.footnote-area .btn-group .btn-paging.btn-info {
  font-weight: var(--weight-semibold);
}

.btn-sm {
  font-size: var(--text-sm) !important;
}

.btn-link {
  border: none !important;
}

.search-dialog .search-header .search-input {
  border-radius: var(--border-radius);
  padding-bottom: 7px;
  background: var(--modal-bg);
  border-bottom: 1px solid var(--border-color);
}
.search-dialog .search-header .search-icon {
  position: absolute;
  top: 16px;
  z-index: 6;
  margin-left: 6px;
}

.modal .modal-body {
  padding: var(--padding-md);
  font-size: var(--text-base);
}

.form-dashboard {
  background-color: var(--subtle-fg);
  font-weight: var(--weight-semibold);
  font-size: var(--text-sm);
}

.modal-minimize .modal-dialog .modal-header {
  padding: var(--padding-md) var(--padding-lg);
}

.modal .modal-header .modal-actions {
  position: absolute;
  top: 14px;
  right: 18px;
}

.timeline-item[data-doctype=Comment] .content {
  background-color: var(--comment-bg) !important;
  border: 1px solid var(--border-color);
  padding: var(--padding-md) !important;
  border-radius: var(--border-radius);
}

.comment-box .comment-input-container .frappe-control .ql-editor {
  background-color: var(--comment-bg) !important;
  padding: var(--padding-md);
}

.indicator-pill .small {
  font-size: var(--text-xs);
}

.comment-input-wrapper .ql-editor.ql-blank::before {
  color: var(--text-muted);
  font-style: normal;
  padding-left: 10px;
}

.timeline-content {
  font-size: var(--text-sm);
}
.timeline-content a {
  font-weight: var(--weight-semibold);
}

.timeline-items {
  padding-right: 10px;
}

.timeline-message-box .content {
  font-size: var(--text-base);
}

.page-form {
  background-color: var(--subtle-fg);
}

.for-login .page-card,
.for-forgot .page-card,
.for-login-with-email-link .page-card,
.for-signup .page-card,
.for-email-login .page-card {
  background-color: var(--bg-color) !important;
  max-width: 400px !important;
  padding: 45px 10px !important;
  margin: 0px auto !important;
  border-radius: var(--border-radius) !important;
  border: 1px solid var(--border-color) !important;
}

.form-message {
  padding: var(--padding-lg);
  margin-top: var(--margin-sm);
  border: 1px solid var(--border-color) !important;
}

.form-section.card-section,
.form-dashboard-section {
  padding: var(--padding-md) var(--padding-xs);
}

.form-message .close-message {
  display: none;
}

.search-bar .awesomplete input {
  background-color: var(--control-bg);
}

.form-sidebar,
.layout-side-section .modified-by,
.created-by,
.pageview-count {
  font-size: var(--text-xs) !important;
  color: var(--gray-600);
}

.layout-side-section {
  padding-right: 10px;
}

.data-pill a {
  font-weight: var(--weight-semibold);
}

.form-sidebar .sidebar-menu .form-sidebar-items .icon-btn svg {
  width: 14px !important;
  height: 14px !important;
}

#navbar-breadcrumbs {
  font-weight: var(--weight-semibold);
}

.modified-by a,
.created-by a {
  font-weight: var(--weight-semibold);
}

.sidebar-menu {
  font-size: var(--text-xs);
}

input[type=checkbox] {
  border: 1px solid var(--border-color);
}

.form-assignments svg,
.form-attachments svg,
.form-reviews svg,
.form-tags svg,
.form-shared svg {
  margin-right: 4px;
}

.form-sidebar-items .icon-btn svg {
  margin-right: 0;
}

.section-head {
  font-size: var(--text-base) !important;
}

.form-tabs-list .form-tabs .nav-item .nav-link {
  font-weight: var(--weight-semibold);
}
.form-tabs-list .form-tabs .nav-item .nav-link.active {
  font-weight: var(--weight-bold);
  border-bottom: 2px solid var(--primary);
}

.list-sidebar-button {
  border: 1px solid var(--border-color);
  background-color: var(--control-bg-on-gray) !important;
}

.frappe-control[data-fieldtype=Select] .control-input .select-icon,
.frappe-control[data-fieldtype=Select].form-group .select-icon {
  padding-left: inherit;
  padding-right: inherit;
  position: absolute;
  pointer-events: none;
  top: 2px;
  right: 6px;
}

.frappe-control[data-fieldtype=Select] .control-input .select-icon.xs,
.frappe-control[data-fieldtype=Select].form-group .select-icon.xs {
  top: 2px;
  right: 7px;
}

.datepicker--cell.-selected-,
.datepicker--cell.-current-.-selected- {
  background-color: var(--primary);
  border-color: var(--primary);
}
.datepicker--cell.-selected-:hover,
.datepicker--cell.-current-.-selected-:hover {
  background-color: var(--primary-dark);
  border-color: var(--primary-dark);
}

.fc-unthemed .fc-today .fc-day-number {
  background-color: var(--primary);
}

.link-primary {
  color: var(--primary);
}
.link-primary:hover, .link-primary:focus {
  color: var(--primary-dark) !important;
}

.text-primary {
  color: var(--primary);
}

a.text-primary:hover {
  color: var(--primary-dark);
}

.bg-primary {
  color: var(--control-bg);
  background-color: var(--primary);
}

a.bg-primary:hover {
  background-color: var(--primary-dark);
}

.dropdown-item,
.dropdown-menu {
  border-radius: var(--border-radius);
}

.layout-main-section {
  margin-bottom: var(--padding-lg);
}

@media (max-width: 767.98px) {
  .frappe-card {
    border: 1px solid var(--border-color) !important;
  }
}
@media (max-width: 575.98px) {
  .layout-main-section,
  .new-timeline {
    margin-left: -15px !important;
    margin-right: -15px !important;
    border-radius: 0px !important;
  }
  .form-page,
  .form-message {
    border-radius: 0px !important;
  }
  .show-all-activity {
    display: none !important;
  }
  .new-timeline {
    padding-left: 15px !important;
  }
  .new-timeline::before {
    border: none !important;
    border-left: none !important;
  }
}
.new-timeline .timeline-item .timeline-content.frappe-card .content {
  margin: var(--padding-sm) !important;
  padding-left: 0px;
}

.new-timeline .timeline-item .timeline-message-box .content::before {
  display: none !important;
}

[data-theme=dark] {
  --gray-50: #1a1e24;
  --gray-100: #20252c;
  --gray-200: #262d35;
  --gray-300: #2d353f;
  --gray-400: #3a434d;
  --gray-500: #4a545f;
  --gray-600: #697684;
  --gray-700: #8b97a4;
  --gray-800: #aeb8c2;
  --gray-900: #d0d7de;
  --bg-color: #161a1f;
  --fg-color: var(--gray-100);
  --navbar-bg: var(--gray-50);
  --modal-bg: var(--gray-100);
  --card-bg: var(--gray-50);
  --subtle-accent: var(--gray-200);
  --subtle-fg: var(--gray-100);
  --control-bg: var(--gray-300);
  --control-bg-on-gray: var(--gray-400);
  --disabled-control-bg: var(--gray-500);
  --awesomplete-hover-bg: var(--gray-400);
  --text-color: var(--gray-800);
  --weight-regular: 500;
  --text-muted: var(--gray-700);
  --text-light: var(--gray-600);
  --heading-color: var(--gray-900);
  --text-neutral: var(--gray-700);
  --neutral: var(--gray-900);
  --disabled-text-color: var(--gray-700);
  --border-color: var(--gray-300);
  --dark-border-color: var(--gray-400);
  --table-border-color: var(--gray-300);
  --btn-primary: var(--primary-dark);
  --btn-default-bg: var(--gray-200);
  --btn-default-hover-bg: var(--gray-300);
  --sidebar-select-color: var(--gray-300);
  --shadow-inset: inset 0px -1px 0px var(--gray-400);
  --highlight-color: var(--gray-200);
  --yellow-highlight-color: #3d3c2a;
  --scrollbar-thumb-color: var(--gray-400);
  --scrollbar-track-color: var(--gray-500);
  --scrollbar-color: var(--gray-200);
  --control-bg: var(--gray-100);
  --control-bg-on-gray: var(--gray-200);
  --input-disabled-bg: var(--gray-200);
  --comment-bg: #363525;
  --comment-border-color: #565438;
  --code-block-bg: var(--gray-100);
  --code-block-text: var(--gray-700);
  --checkbox-color: var(--gray-800);
  --checkbox-gradient: linear-gradient(
    180deg,
    var(--primary) -124.51%,
    var(--primary) 100%
  );
  --checkbox-disabled-gradient: linear-gradient(
    180deg,
    var(--gray-400) -124.51%,
    var(--gray-400) 100%
  );
  --switch-bg: var(--gray-500);
  --alert-text-danger: var(--red-300);
  --alert-text-warning: var(--yellow-300);
  --alert-text-info: var(--blue-300);
  --alert-text-success: var(--green-300);
  --alert-bg-danger: rgba(220, 53, 69, 0.1);
  --alert-bg-warning: rgba(255, 193, 7, 0.1);
  --alert-bg-info: rgba(23, 162, 184, 0.1);
  --alert-bg-success: rgba(40, 167, 69, 0.1);
  --bg-blue: rgba(0, 123, 255, 0.1);
  --bg-light-blue: rgba(0, 123, 255, 0.05);
  --bg-dark-blue: rgba(0, 123, 255, 0.2);
  --bg-green: rgba(40, 167, 69, 0.1);
  --bg-yellow: rgba(255, 193, 7, 0.1);
  --bg-orange: rgba(253, 126, 20, 0.1);
  --bg-red: rgba(220, 53, 69, 0.1);
  --bg-gray: var(--gray-200);
  --bg-light-gray: var(--gray-100);
  --bg-dark-gray: var(--gray-300);
  --bg-purple: rgba(111, 66, 193, 0.1);
  --bg-pink: rgba(232, 62, 140, 0.1);
  --bg-cyan: rgba(23, 162, 184, 0.1);
  --text-on-blue: var(--blue-300);
  --text-on-light-blue: var(--blue-200);
  --text-on-dark-blue: var(--blue-100);
  --text-on-green: var(--green-300);
  --text-on-yellow: var(--yellow-300);
  --text-on-orange: var(--orange-300);
  --text-on-red: var(--red-300);
  --text-on-gray: var(--gray-800);
  --text-on-light-gray: var(--gray-700);
  --text-on-dark-gray: var(--gray-500);
  --text-on-purple: var(--purple-300);
  --text-on-pink: var(--pink-300);
  --text-on-cyan: var(--cyan-300);
  --icon-stroke: var(--gray-800);
  --avatar-frame-bg: var(--gray-100);
  --avatar-frame-border: var(--gray-100);
  --skeleton-bg: var(--gray-200);
  --fg-hover-color: var(--gray-400);
  --btn-group-border-color: var(--gray-300);
  --timeline-badge-bg: var(--gray-200);
  --shadow-sm: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-base: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --shadow-xl: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  --modal-backdrop-bg: rgba(0, 0, 0, 0.75);
  --modal-bg: var(--gray-100);
  --input-bg: var(--gray-200);
  --input-disabled-bg: var(--gray-300);
  --modal-border-color: var(--gray-400);
  --modal-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
[data-theme=dark] input:checked + .slider {
  background-color: var(--primary-dark);
}
[data-theme=dark] .new-timeline .timeline-item .timeline-content.frappe-card {
  background-color: var(--card-bg);
}
[data-theme=dark] .btn-default {
  background-color: var(--gray-200);
  border-color: var(--gray-300);
  color: var(--gray-800);
}
[data-theme=dark] .btn-default:hover, [data-theme=dark] .btn-default:focus {
  background-color: var(--gray-300);
  border-color: var(--gray-400);
  color: var(--gray-900);
}
[data-theme=dark] .progress {
  background-color: var(--gray-300);
}
[data-theme=dark] .form-control {
  background-color: var(--control-bg);
  border-color: var(--border-color);
  color: var(--text-color);
}
[data-theme=dark] .form-control:focus {
  border-color: var(--primary-dark);
  box-shadow: 0 0 0 0.2rem rgba(var(--primary-rgb), 0.25);
}
[data-theme=dark] .modal-content {
  background-color: var(--modal-bg);
  border-color: var(--border-color);
}
[data-theme=dark] .list-row-head {
  border-bottom: 1px solid var(--border-color);
}
[data-theme=dark] .timeline-item {
  border-color: var(--border-color);
}
[data-theme=dark] .frappe-card {
  background-color: var(--card-bg);
}
[data-theme=dark] .page-form {
  background-color: var(--card-bg);
}
[data-theme=dark] .form-dashboard {
  background-color: var(--gray-100);
}
[data-theme=dark] .like-disabled-input {
  background-color: var(--gray-100);
}
[data-theme=dark] .ql-toolbar.ql-snow {
  background-color: var(--gray-200) !important;
}
[data-theme=dark] .print-preview-wrapper {
  background-color: var(--gray-100);
}
[data-theme=dark] [data-page-route=Workspaces] .layout-main-section {
  background-color: var(--gray-50);
}
[data-theme=dark] .modal-backdrop {
  background-color: var(--modal-backdrop-bg);
}
[data-theme=dark] .modal-content {
  background-color: var(--modal-bg);
  border-color: var(--border-color);
}
[data-theme=dark] .form-control,
[data-theme=dark] .input-with-feedback {
  background-color: var(--input-bg);
  border-color: var(--border-color);
  color: var(--text-color);
}
[data-theme=dark] .form-control:focus,
[data-theme=dark] .input-with-feedback:focus {
  border-color: var(--primary);
  box-shadow: 0 0 0 0.2rem rgba(var(--primary-rgb), 0.25);
}
[data-theme=dark] .form-control:disabled, [data-theme=dark] .form-control[readonly],
[data-theme=dark] .input-with-feedback:disabled,
[data-theme=dark] .input-with-feedback[readonly] {
  background-color: var(--input-disabled-bg);
  color: var(--disabled-text-color);
}
[data-theme=dark] .checkbox input[type=checkbox] {
  border-color: var(--border-color);
  background-color: var(--input-bg);
}
[data-theme=dark] .checkbox input[type=checkbox]:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}
[data-theme=dark] .modal-header,
[data-theme=dark] .modal-footer {
  border-color: var(--border-color);
}
[data-theme=dark] .control-label {
  color: var(--text-muted);
}
[data-theme=dark] .modal-content {
  background-color: var(--modal-bg);
  border-color: var(--modal-border-color);
  box-shadow: var(--modal-shadow);
}
[data-theme=dark] .modal-header,
[data-theme=dark] .modal-footer {
  border-color: var(--modal-border-color);
}
[data-theme=dark] .modal-body hr {
  border-color: var(--modal-border-color);
}
[data-theme=dark] .modal-header .close {
  color: var(--text-muted);
}
[data-theme=dark] .modal-header .close:hover {
  color: var(--text-color);
}
[data-theme=dark] .modal-title {
  color: var(--text-color);
}
[data-theme=dark] .modal,
[data-theme=dark] .form-in-grid {
  --control-bg: var(--control-bg);
  --border-color: var(--modal-border-color);
}
[data-theme=dark] .modal .ql-editor {
  border-color: var(--border-color);
}
[data-theme=dark] .modal-footer .btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
}
[data-theme=dark] .modal-footer .btn-primary:hover {
  background-color: var(--primary-dark);
  border-color: var(--primary-dark);
}
[data-theme=dark] .modal-footer .btn-secondary {
  background-color: var(--gray-300);
  border-color: var(--gray-400);
  color: var(--text-color);
}
[data-theme=dark] .modal-footer .btn-secondary:hover {
  background-color: var(--gray-400);
  border-color: var(--gray-500);
}
[data-theme=dark] .modal-backdrop {
  background-color: var(--modal-backdrop-bg);
}

[data-theme=light] {
  --gray-25: #f8f8f8;
  --gray-50: #f9fafa;
  --gray-100: #f4f5f6;
  --gray-200: #eef0f2;
  --gray-300: #e2e6e9;
  --gray-350: #d4d9de;
  --gray-400: #c8cfd5;
  --gray-500: #a6b1b9;
  --gray-600: #74808b;
  --gray-700: #4c5a67;
  --gray-800: #313b44;
  --gray-900: #192734;
  --navbar-bg: #f5f7fa;
  --disabled-text-color: var(--gray-700);
  --comment-bg: #fffce7;
  --comment-border-color: #efdaa3;
  --awesomplete-hover-bg: var(--gray-100);
  --subtle-fg: #fafbfc;
  --heading-color: var(--gray-900);
  --text-neutral: var(--gray-800);
  --text-color: var(--gray-800);
  --text-muted: var(--gray-700);
  --text-light: var(--gray-600);
  --border-color: var(--gray-350);
  --table-border-color: var(--border-color);
  --btn-primary: var(--primary);
  --btn-default-bg: var(--navbar-bg);
  --btn-default-hover-bg: var(--gray-300);
  --border-primary: var(--primary);
  --blue: var(--primary);
  --control-bg: white;
  --control-bg-on-gray: var(--gray-50);
  --avatar-frame-bg: var(--navbar-bg);
  --switch-bg: var(--gray-400);
  --invert-neutral: var(--primary);
}
[data-theme=light] #freeze .freeze-message-container {
  background-color: var(--gray-600) !important;
}